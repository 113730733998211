import { HeaderHeight } from '../models/PageModel'
import { breakpoints } from '../constants/Layout'

const RETURN_TO_URL_WHITELIST = [
  'https://forum.vention.io/auth/oauth2_basic'
] as const;

/**
 * Used to calculate the current header nav height.
 *
 */
export function getHeaderHeight(): number {
  return window.innerWidth > breakpoints.navMainLarge ? HeaderHeight.desktop : HeaderHeight.mobile
}

/**
 * Used to smooth scroll to element in the window.
 *
 * @param element
 */
export function scrollTo(element, behaviour?: ScrollBehavior): void {
  window.scroll({
    behavior: behaviour || 'smooth',
    left: 0,
    top: element.getBoundingClientRect().top + window.scrollY - getHeaderHeight(),
  })
}

export function redirectWithReturnTo(path: string, returnTo?: string): string {
  if (!returnTo) {
    return path;
  }

  // Check if returnTo starts with any whitelisted URL
  const isWhitelisted = RETURN_TO_URL_WHITELIST.some((url) =>
    returnTo.toLowerCase().startsWith(url.toLowerCase()),
  );

  if (isWhitelisted) {
    return returnTo;
  }

  // If it starts with a slash, treat it as a path
  if (returnTo.startsWith("/")) {
    const sanitizedPath = returnTo.replace(/\/+/g, "/"); // Replace multiple slashes with single slash

    return sanitizedPath;
  }

  // For anything else, fallback to default path
  return path;
}
